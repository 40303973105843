import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Toaster } from "@/components/ui/toaster";
import { useSupabase } from "@/lib/supabase";
import styles from "@/tailwind.css?url";
import {
  MetaFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteError,
} from "@remix-run/react";
import { Loader2, WifiOff } from "lucide-react";
import {
  getSupabaseEnv,
  getSupabaseWithSessionAndHeaders,
} from "./lib/supabase.server";
import { useOnlineStatus } from "./lib/utils";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: "Daily Bulk Wine" },
    {
      name: "twitter:title",
      content: "Daily Bulk Wine",
    },
    {
      name: "og:title",
      content: "Daily Bulk Wine",
    },
    {
      property: "og:title",
      content: "Daily Bulk Wine",
    },
    {
      name: "description",
      content: "Connecting bulk wine buyers & sellers",
    },
    {
      name: "twitter:description",
      content: "Connecting bulk wine buyers & sellers",
    },
    {
      property: "og:description",
      content: "Connecting bulk wine buyers & sellers",
    },
    {
      name: "twitter:image",
      content: `${data?.domainUrl}/social-preview.png`,
    },
    {
      property: "og:image",
      content: `${data?.domainUrl}/social-preview.png`,
    },
    {
      name: "twitter:card",
      content: `${data?.domainUrl}/social-preview.png`,
    },
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { serverSession, headers } = await getSupabaseWithSessionAndHeaders({
    request,
  });
  const domainUrl = process.env.DOMAIN_URL!;

  const env = getSupabaseEnv();

  return json({ serverSession, env, domainUrl }, { headers });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { env, serverSession, domainUrl } = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  const { supabase } = useSupabase({ env, serverSession });
  const isOnline = useOnlineStatus();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          defer
          src="https://analytics.eugenescheepers.com/script.js"
          data-website-id="ea9385b7-3c96-4c4e-8556-2957c3552ebb"
        ></script>
      </head>
      <body className="overscroll-none relative">
        {navigation.state === "loading" && (
          <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-white opacity-75">
            <Loader2 className="h-12 w-12 animate-spin text-primary bg-transparent" />
          </div>
        )}
        <Toaster />
        {/*<div className="flex w-full mx-auto bg-yellow-300 p-4 justify-center">
          <p className="text-center break-words">
            We are currently busy doing upgrades on our website, please do not
            use the site until this banner is removed.
          </p>
        </div>*/}
        {!isOnline && (
          <div className="flex w-full mx-auto bg-red-300 p-4 justify-center">
            <WifiOff className="mx-2" />
            <p className="text-center break-words">
              You are offline. Please check your internet connection.
            </p>
          </div>
        )}
        <Outlet context={{ supabase, domainUrl }} />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}